import { QueryDocumentSnapshot } from 'firebase/firestore';

import { getCouponType } from '@helpers/coupon.helper';
import { getOrderFarmStatus, getOrderStatus } from '@helpers/orderStatus.helper';
import { getOrderTimelineUpdatedBy } from '@helpers/orderTimeline.helper';
import { getWeightUnit } from '@helpers/units.helper';
import { FSOrder, FSOrderFarm, FSOrderFarmCoupon, FSOrderFarms, Order } from '@models/orders.model';

import { getDateFromFirestore } from '../networkService';

export const parseToOrder = (doc: QueryDocumentSnapshot<FSOrder>): Order => {
  const farms = Object.entries(doc.data()['farms']).reduce((acc: FSOrderFarms, [farmID, farm]) => {
    const coupon: FSOrderFarmCoupon | undefined =
      farm.coupon && farm.coupon?.type
        ? {
            ...farm.coupon!,
            type: getCouponType(farm.coupon!.type),
          }
        : undefined;
    const products = farm.products.map(p => ({
      ...p,
      variantUnit: getWeightUnit(p.variantUnit) ?? p.variantUnit,
    }));
    const updatedFarm: FSOrderFarm = { ...farm, coupon, products };
    return { ...acc, [farmID]: updatedFarm };
  }, {});

  const date = getDateFromFirestore(doc.data()['orderDate']);
  const status = getOrderStatus(doc.data().status);

  return {
    id: doc.id,
    ...(doc.data() as FSOrder),
    orderDate: date!,
    status: status,
    farms,
    timeline: doc.data().timeline
      ? doc.data().timeline.map(update => ({
          ...update,
          timestamp: getDateFromFirestore(update.timestamp),
          statusUpdate: getOrderFarmStatus(update.statusUpdate),
          updatedBy: getOrderTimelineUpdatedBy(update.updatedBy),
        }))
      : [],
  };
};
