import React from 'react';

interface Props {
  icon: string;
  text: string;
  disabled?: boolean;
  onClick: () => void;
}

const OAuthButton: React.FC<Props> = ({ icon, text, disabled, onClick }) => {
  const CSSBlock = 'oauth-button';

  return (
    <button
      className={`${CSSBlock} ${CSSBlock}--${icon}`}
      disabled={disabled}
      onClick={onClick}>
      <span className={`${CSSBlock}__logo`}>
        <i className={`fa-brands ${icon}`}></i>
      </span>
      <span className={`${CSSBlock}__text`}>{text}</span>
    </button>
  );
};

export default OAuthButton;
