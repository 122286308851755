import React from 'react';

import DemmiButtonSwitch from '@demmi-ui/Buttons/ButtonSwitch';
// import DemmiButtonSwitch from '@demmi-ui/Buttons/ButtonSwitch';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import { customerGenders, customerTitles } from '@helpers/customer.helper';
import { FSUserModel } from '@models/customer.model';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  customer: FSUserModel;
  onUpdateCustomer: (customer: FSUserModel) => void;
}
const Screen02: React.FC<Props> = ({ isMounted, mountedStyle, unmountedStyle, customer, onUpdateCustomer }) => {
  const CSSBlock = 'welcome-section';

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: customerTitles.map(
            t =>
              ({
                id: t,
                text: t,
                item: t,
              } as MenuItem<string>)
          ),
        },
      ],
    };
  };

  return (
    <ScreenWrapper
      cardIndex={2}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={'Your Details'}>
      <div className={`${CSSBlock}__inputs`}>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>Title</div>
          <Menu<string>
            content={getMenuContent()}
            triggerEl={<MenuTriggerButton text={customer.title} styles={{ width: '100%' }} />}
            onClick={title => onUpdateCustomer({ ...customer, title })}
          />
        </div>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>Your first name</div>
          <DemmiInput
            onChangeValue={val => onUpdateCustomer({ ...customer, firstName: val })}
            type="text"
            placeholder="First Name"
            value={customer.firstName}
          />
        </div>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>Your last name</div>
          <DemmiInput
            onChangeValue={val => onUpdateCustomer({ ...customer, lastName: val })}
            type="text"
            placeholder="Last Name"
            value={customer.lastName}
          />
        </div>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>What do you prefer to be called?</div>
          <DemmiInput
            onChangeValue={val => onUpdateCustomer({ ...customer, nickname: val })}
            type="text"
            placeholder="Nickname"
            value={customer.nickname}
          />
        </div>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>And your gender?</div>
          <DemmiButtonSwitch
            buttons={Object.values(customerGenders)}
            initialIndex={customer.gender}
            onClick={i => onUpdateCustomer({ ...customer, gender: i })}
          />
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default Screen02;
