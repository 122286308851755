import { doc, updateDoc } from 'firebase/firestore';

import { FBAuthProvider } from '@helpers/authProvider.helper';
import { FSUserHomeAddress, FSUserModel } from '@models/customer.model';
import { FSCollections } from '@providers/firestoreProvider';
import { Logger } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { onSetupCustomer } from '../_functions';
import { FSCollectionNames } from '../networkService';
import { FSCustomer } from './customer';

export class FSCustomerProfile {
  static _updateDetails = async (
    customerDocID: string,
    title: string,
    firstName: string,
    lastName: string,
    nickname: string,
    contactNumber: string,
    gender: number
  ) => {
    // Only setup user if document already exists.
    const customerExists = await FSCustomer.doesCustomerExistByDocID(customerDocID);
    Logger({ objs: { customerExists } }, this._updateDetails);
    if (!customerExists) return;

    const docRef = doc(FSCollections.Users, customerDocID);
    return updateDoc(docRef, {
      title,
      firstName,
      lastName,
      nickname,
      contactNumber,
      gender,
    });
  };

  static _updateHomeAddress = async (customerDocID: string, address: FSUserHomeAddress) => {
    // Only setup user if document already exists.
    const customerExists = await FSCustomer.doesCustomerExistByDocID(customerDocID);
    Logger({ objs: { customerExists } }, this._updateHomeAddress);
    if (!customerExists) return;

    Logger({ objs: { customerDocID, address } }, this._updateHomeAddress);
    const docRef = doc(db, FSCollectionNames.USERS, customerDocID);
    return updateDoc(docRef, {
      'address.home': address,
    });
  };

  static _setupCustomer = async (authUID: string, provider: FBAuthProvider, customer: FSUserModel) => {
    // Only set up user if document already exists.
    const docID = await FSCustomer.doc.getID(authUID, provider);
    Logger({ objs: { docID } }, this._setupCustomer);
    if (!docID) return;

    Logger({ objs: { authUID, provider } }, this._setupCustomer);
    const docRef = doc(FSCollections.Users, docID);
    return updateDoc(docRef, {
      title: customer.title,
      firstName: customer.firstName,
      lastName: customer.lastName,
      nickname: customer.nickname,
      contactNumber: customer.contactNumber,
      gender: customer.gender,
      address: {
        countryCode: customer.address.countryCode,
      },
      ...(customer.email ? { email: customer.email } : {}),
    }).then(() => {
      onSetupCustomer(customer);
    });
  };
}
