import React, { useEffect } from 'react';

import { CSSVARS, setCSSVar } from '@helpers/theme.helper';

const fps = 60;
const duration = 2;
const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;

interface AppThemeProps {
  isLogin: boolean;
}

let themeInterval = 0;

const AppTheme: React.FC<AppThemeProps> = ({ isLogin }) => {
  const animate = () => {
    requestAnimationFrame(() => {
      // if (process.env.IS_PRODUCTION) {
      //   animatePos();
      // }
    });
  };

  let pos = 0;
  const animatePos = () => {
    pos += 1 / fps / duration;
    setCSSVar(CSSVARS.THEME_BG_POS, (0 + easeInOutSine(pos) * 100).toString());
  };

  useEffect(() => {
    // isLogin
    //   ?
    themeInterval = window.setInterval(animate, 1000 / fps);
    // : clearInterval(themeInterval);
    return () => clearInterval(themeInterval);
  }, [isLogin]);

  useEffect(() => {
    return () => clearInterval(themeInterval);
  }, []);

  return <></>;
};

export default AppTheme;
