export enum WeightUnit {
  kilogram = 'weight_kg',
  gram = 'weight_gram',
  pound = 'weight_lbs',
  ounce = 'weight_oz',
  each = 'weight_each',
}

export const getWeightUnit = (unit?: string): WeightUnit | undefined => {
  const match = Object.entries(WeightUnit).find(([_, val]) => val === unit);
  return match ? match[1] : undefined;
};

export const getWeightUnitText = (unit: WeightUnit): string => {
  switch (unit) {
    case WeightUnit.kilogram:
      return 'kg';
    case WeightUnit.gram:
      return 'grams';
    case WeightUnit.pound:
      return 'lbs';
    case WeightUnit.ounce:
      return 'oz';
    case WeightUnit.each:
      return 'each';
    default:
      return '-';
  }
};
