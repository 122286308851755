import { httpsCallable } from 'firebase/functions';

import { FSUserModel } from '@models/customer.model';
import { Logger } from '@subhanhabib/demmilib';

import { functions } from '../../../firebase';
import { CLOUD_FUNCTIONS } from './networkService';

export const onSetupCustomer = async (user: FSUserModel) => {
  const addMessage = httpsCallable(functions, CLOUD_FUNCTIONS.ON_SETUP_COMPLETE);
  addMessage(user).then(result => {
    // Read result of the Cloud Function.
    // /** @type {any} */
    const data = result.data;
    Logger({ objs: { FormData } }, onSetupCustomer);
  });
};
