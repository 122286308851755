import { FBAuthProvider, FBAuthProviderInfo } from '@helpers/authProvider.helper';

export interface FSUserDoc extends FSUserModel {
  docID: string;
}

export interface FSUserHomeAddress {
  [key: string]: string;
}

export interface FSUserAddress {
  home?: FSUserHomeAddress;
  countryCode: string;
}
export interface FSUserModel {
  title: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email?: string;
  contactNumber: string;
  gender: number;
  lastActive: Date;
  farmFavourites: string[];
  address: FSUserAddress;
  fb_fcmTokens: string[];
  fb_authProviders: { [key in FBAuthProvider]?: FBAuthProviderInfo };
}

export const EMPTY_CUSTOMER: FSUserModel = {
  address: { countryCode: '' },
  contactNumber: '',
  title: '',
  firstName: '',
  lastName: '',
  nickname: '',
  gender: 0,
  fb_authProviders: {},
  lastActive: new Date(),
  farmFavourites: [],
  fb_fcmTokens: [],
};

export const MOCK_CUSTOMER: FSUserModel = {
  address: { countryCode: '' },
  contactNumber: '+447818112073',
  title: 'Mr',
  firstName: 'Subhan',
  lastName: 'Habib',
  nickname: 'Subsy',
  gender: 0,
  fb_authProviders: {},
  lastActive: new Date(),
  farmFavourites: [],
  fb_fcmTokens: [],
};
