import { forwardRef } from 'react';
import { DefaultInputComponentProps } from 'react-phone-number-input';

import DemmiInput from './Input';

const DemmiInputPhone = forwardRef<any, DefaultInputComponentProps>((props, ref) => {
  return <DemmiInput type="text" ref={ref} value={props.value} onChangeValue={props.onChange} />;
});

export default DemmiInputPhone;
