import React from 'react';

import DemmiItemSwitch from '../ItemSwitch';

interface DemmiButtonSwitchProps {
  buttons: string[];
  initialIndex?: number;
  onClick: (i: number) => Promise<boolean> | void;
}

const DemmiButtonSwitch: React.FC<DemmiButtonSwitchProps> = ({ buttons, initialIndex, onClick }) => {
  const CSSBlock = 'demmi-ui-button-switch';

  const items = buttons.map((button, i) => (
    <div className={`${CSSBlock}__item`} key={i}>
      <span className={`${CSSBlock}__item-text`}>{button}</span>
    </div>
  ));

  return (
    <div className={`${CSSBlock}`}>
      <DemmiItemSwitch items={items} onClick={onClick} initialIndex={initialIndex} />
    </div>
  );
};

export default DemmiButtonSwitch;
