import React from 'react';

interface Props {
  isLoading: boolean;
}

const ElementLoadingIndicator: React.FC<Props> = ({ isLoading }) => {
  const CSSBlock = 'element-loading-indicator';

  return (
    <span className={`${CSSBlock} ${isLoading ? `${CSSBlock}--loading` : ``}`}>
      <div className={`${CSSBlock}__loading-indicator`} />
    </span>
  );
};

export default ElementLoadingIndicator;
