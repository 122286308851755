import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectCustomer } from '@core/store/userSlice/userSlice';

import CustomerAddress from './partials/CustomerAddress';
import CustomerDetails from './partials/CustomerDetails';

interface Props {}

const YourAccount: React.FC<Props> = ({}) => {
  const CSSBlock = 'your-account';
  const customer = useAppSelector(selectCustomer);

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__title`}>Manage Your Account</div>
      {customer && <CustomerDetails customer={customer} />}
      {customer && <CustomerAddress customer={customer} />}
    </div>
  );
};

export default YourAccount;
