import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import '@assets/fa-all.min.js';
import AppCore from '@core/AppCore';
import { store } from '@core/store/store';
import { FirestoreProvider } from '@providers/firestoreProvider';
import '@styles/index.scss';

const root = createRoot(document.getElementById('demmi-portal')!);
root.render(
  <Provider store={store}>
    <FirestoreProvider>
      <AppCore />
    </FirestoreProvider>
  </Provider>
);
