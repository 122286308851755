import { doc, query, where } from 'firebase/firestore';

import { FSCollections } from '@providers/firestoreProvider';

import { FSSubCollectionNames } from '../networkService';

// ----------
// Common Queries
// ----------

export const orderQuery = (orderDocID: string) => doc(FSCollections.Orders, orderDocID);

export const getOrdersQuery = (customerID: string) =>
  query(
    FSCollections.Orders,
    where(`customerID`, '==', customerID) // only if this farm exists
  );

export const orderReviewsFarmQuery = (farmID: string, orderID: string) =>
  query(FSCollections.OrderReviews([farmID, FSSubCollectionNames.REVIEWS]), where('orderID', '==', orderID));
