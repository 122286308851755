import { FSUserHomeAddress, FSUserModel } from '@models/customer.model';

import { RegionField, getRegionAddress } from './address.helper';

export const customerTitles = ['Mr', 'Mrs', 'Ms', 'Miss', 'Mx'];
export enum customerGenders {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}
export const getCustomerGender = (gender: number) => {
  switch (gender) {
    case 0:
      return customerGenders.MALE;
    case 1:
      return customerGenders.FEMALE;
    case 2:
      return customerGenders.OTHER;
    default:
      return customerGenders.OTHER;
  }
};

// export interface customerDetailsField {
//   label: string;
//   required: boolean;
//   isValid: boolean;
//   values?: string[];
// }

// export interface customerDetails {
//   title: customerDetailsField;
//   firstName: customerDetailsField;
//   lastName: customerDetailsField;
//   nickname: customerDetailsField;
//   contactNumber: customerDetailsField;
// }

// export const customerDetailFields: customerDetails = {
//   title: {
//     label: 'Title',
//     required: true,
//     isValid: false,
//     values: customerTitles,
//   },
//   firstName: { label: 'First Name', required: true, isValid: false },
//   lastName: { label: 'Last Name', required: true, isValid: false },
//   nickname: { label: 'Nickname', required: true, isValid: false },
//   contactNumber: { label: 'Contact Number', required: true, isValid: false },
// };

export const isCustomerSetupComplete = (customer: FSUserModel) => {
  return !!(customer.title && customer.firstName && customer.lastName);
};

export const isCustomerRegionValid = (customer: FSUserModel) => {
  return true;
};

export const isCustomerDetailsValid = (
  customer: Pick<FSUserModel, 'title' | 'firstName' | 'lastName' | 'nickname' | 'contactNumber' | 'address'>
) => {
  return (
    isContactNumberValid(customer.contactNumber, customer.address?.countryCode) &&
    customer.title.length >= 2 &&
    isCustomerFirstNameValid(customer.firstName) &&
    isCustomerLasttNameValid(customer.lastName) &&
    isCustomerNicknameValid(customer.nickname)
  );
};

const isContactNumberValid = (num: string, countryCode: string) => true;
// /^\d{11}$/.test(num);

export const isCustomerFirstNameValid = (s: string) => s.length >= 2;
export const isCustomerLasttNameValid = (s: string) => s.length >= 2;
export const isCustomerNicknameValid = (s: string) => s.length >= 2;

export const isCustomerAddressValid = (address: FSUserHomeAddress, countryCode?: string): boolean => {
  if (!countryCode) return false;
  const isAnyInvalid = () => {
    return Object.entries(getRegionAddress(countryCode)!.address).filter(([key, val]: [string, RegionField]) => {
      return val.validate != null ? !val.validate(address[key]) : false;
    });
  };

  return getRegionAddress(countryCode) != null && isAnyInvalid().length === 0;
};
