import { doc, getDoc } from 'firebase/firestore';

import { FBAuthProvider } from '@helpers/authProvider.helper';
import { Logger } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { FSCollectionNames } from '../networkService';
import { FSCustomerAuth } from './_auth';
import { FSCustomerProfile } from './_profile';

export class FSCustomer {
  static doc = {
    getByAuth: FSCustomerAuth._getDocByAuth,
    getByDocID: FSCustomerAuth._getDocByID,
    getID: FSCustomerAuth._getDocID,
    getByProvider: FSCustomerAuth._getByProvider,
    getByProviderApple: FSCustomerAuth._getByProviderApple,
    getByProviderGoogle: FSCustomerAuth._getByProviderGoogle,
    createNewDoc: FSCustomerAuth._createNewDoc,
  };

  static profile = {
    updateDetails: FSCustomerProfile._updateDetails,
    updateHomeAddress: FSCustomerProfile._updateHomeAddress,
    setupCustomer: FSCustomerProfile._setupCustomer,
  };

  static doesCustomerExistByDocID = async (customerDocID: string): Promise<boolean> => {
    Logger({ objs: { customerDocID } }, this.doesCustomerExistByDocID);
    const docSnap = await getDoc(doc(db, FSCollectionNames.USERS, customerDocID));
    return docSnap.exists();
  };

  static doesCustomerExistByAuth = async (authUID: string, provider: FBAuthProvider): Promise<boolean> => {
    Logger({ objs: { authUID, provider } }, this.doesCustomerExistByAuth);
    return !!FSCustomer.doc.getID(authUID, provider);
  };
}
