import React from 'react';

import { useAppDispatch } from '@core/hooks/appHooks';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { useAuth } from '@providers/authProvider';

import ThemeSwitcher from '../../components/themeSwitcher/ThemeSwitcher';

interface Props {}

const WelcomeFooter: React.FC<Props> = ({}) => {
  const CSSBlock = 'welcome';
  const dispatch = useAppDispatch();
  const { logOut } = useAuth();

  return (
    <div className={`${CSSBlock}__footer`}>
      <div className={`${CSSBlock}__logout-button`}>
        <DemmiIconButton
          invert
          faIcon="fa-arrow-right-from-bracket"
          onClick={() => logOut(() => dispatch({ type: 'LOGOUT' }))}
        />
      </div>
      <ThemeSwitcher />
    </div>
  );
};

export default WelcomeFooter;
