import { matchPath } from 'react-router-dom';

import { Logger } from '@subhanhabib/demmilib';

export enum SearchParams {
  ORDER_ID = 'orderID',
}

export enum RouteParams {
  FARM_ID = 'farmID',
  ORDER_ID = 'orderID',
}

export enum RoutingViews {
  HOME = 'home',

  ORDER_HISTORY = 'orders',
  ORDER_HISTORY_DETAILS = 'orders-detail',

  YOUR_ACCOUNT = 'your-account',
  USER_SETUP = 'welcome',
  SETTINGS = 'settings',
}

const _generatePath = (root: RoutingViews, ...children: (RoutingViews | RouteParams)[]) => {
  let path = `/${root}`;
  children.forEach(child => {
    if (Object.values(RouteParams).includes(child as RouteParams)) {
      path = `${path}/:${child}`;
    } else {
      path = `${path}/${child.replaceAll(`${root}-`, '')}`;
    }
  });
  return path;
};

export const routingPaths = {
  [RoutingViews.HOME]: `/${RoutingViews.HOME}`,

  [RoutingViews.ORDER_HISTORY]: _generatePath(RoutingViews.ORDER_HISTORY),
  [RoutingViews.ORDER_HISTORY_DETAILS]: _generatePath(
    RoutingViews.ORDER_HISTORY,
    RouteParams.ORDER_ID,
    RoutingViews.ORDER_HISTORY_DETAILS
  ),

  [RoutingViews.YOUR_ACCOUNT]: `/${RoutingViews.YOUR_ACCOUNT}`,
  [RoutingViews.USER_SETUP]: `/${RoutingViews.USER_SETUP}`,
  [RoutingViews.SETTINGS]: `/${RoutingViews.SETTINGS}`,
};

export const getRouteFromPath = (path: RoutingViews, replacements: { [key: string]: any }) => {
  let p = routingPaths[path];
  Object.entries(replacements).forEach(([key, val]) => {
    p = p.replace(`:${key}`, val);
  });
  Logger({ objs: { p } }, getRouteFromPath);
  return p;
};

export const getViewFromPath = (path: string, exact = true) => {
  return Object.values(RoutingViews).find(view => {
    return matchPath(exact ? routingPaths[view] : routingPaths[view] + '/*', path);
  });
};
