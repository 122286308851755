import React from 'react';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  onClickFinish: () => void;
}
const Screen06: React.FC<Props> = ({ isMounted, mountedStyle, unmountedStyle, onClickFinish }) => {
  const CSSBlock = 'welcome-section';

  return (
    <ScreenWrapper
      cardIndex={6}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={"We've done it!"}>
      <div className={`${CSSBlock}__description`}>It looks like you're ready to get started with Demmi.</div>
      <div className={`${CSSBlock}__description`}>
        Remember, Demmi is in a public beta phase, so there may be some rough edges. But don't worry! We're constantly
        improving the experience for everyone!
      </div>

      <button className={`demmi-ui-button ${CSSBlock}__complete-button`} onClick={onClickFinish}>
        {/* onClick={() => {
          navigate(routingPaths[RoutingViews.HOME], { replace: true });
        }}> */}
        <span className={`${CSSBlock}__icon`}>
          <i className={`fa-light fa-face-smile-tongue`}></i>
        </span>
        <div className={`${CSSBlock}__text`}>Let's Go!</div>
      </button>
    </ScreenWrapper>
  );
};

export default Screen06;
