import React, { useState } from 'react';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppDispatch } from '@core/hooks/appHooks';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import { setCustomer } from '@core/store/userSlice/userSlice';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiInput from '@demmi-ui/Input';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { RegionField, getRegionAddress } from '@helpers/address.helper';
import { isCustomerAddressValid } from '@helpers/customer.helper';
import { FSUserAddress, FSUserDoc } from '@models/customer.model';

interface Props {
  customer: FSUserDoc;
}

const CustomerAddress: React.FC<Props> = ({ customer }) => {
  const CSSBlock = 'customer-details-block';
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState<FSUserAddress | undefined>(customer.address);

  const updateCustomer = async () => {
    if (!address?.home) return;
    setIsLoading(true);
    await FSCustomer.profile.updateHomeAddress(customer.docID, address.home).then(() => {
      FSCustomer.doc.getByDocID(customer.docID).then(customer => {
        dispatch(setCustomer(customer));
        setIsLoading(false);
      });
    });
  };

  const onSave = async () => {
    await updateCustomer();
    setEditMode(false);
  };

  const onCancel = () => {
    setAddress(customer.address);
    setEditMode(false);
  };

  const updateVal = (key: string, val: string) => {
    const updatedAddress: FSUserAddress = {
      ...address!,
      home: { ...address?.home, [key]: val },
    };
    setAddress(updatedAddress);
  };

  const getMenuContent = (items: string[]) => {
    return {
      sections: [
        {
          items: items.map(c => ({
            id: c.replaceAll(' ', ''),
            text: c,
            item: c,
          })),
        },
      ],
    };
  };
  const editModeInput = (key: string, value: RegionField) => (
    <>
      {value.values ? (
        <Menu<string>
          content={getMenuContent(Object.values(value.values))}
          triggerEl={<MenuTriggerButton text={address?.home?.[key] ?? ''} />}
          onClick={val => updateVal(key, val)}
        />
      ) : (
        <DemmiInput
          type="text"
          placeholder={value.hint}
          value={address?.home?.[key] ?? ''}
          maxLength={value.maxLength}
          onChangeValue={val => updateVal(key, val)}
          error={value.validate ? !value.validate(address?.home?.[key]) : false}
        />
      )}
      {value.validate && <span className={`${CSSBlock}__item-value-required`}>Required Field *</span>}
    </>
  );

  return (
    <div
      className={`${CSSBlock} ${editMode ? `${CSSBlock}--edit-mode` : ``} ${isLoading ? `${CSSBlock}--loading` : ``}`}>
      <div className={`${CSSBlock}__section-title`}>
        Your Home Address
        <div className={`${CSSBlock}__control-buttons`}>
          {!editMode && <DemmiIconButton faIcon="fa-pencil" onClick={() => setEditMode(!editMode)} />}
          {editMode && (
            <>
              <DemmiIconButton faIcon="fa-cancel" onClick={() => onCancel()} />
              <DemmiIconButton
                faIcon="fa-save"
                onClick={() => onSave()}
                disabled={!address?.home || !isCustomerAddressValid(address!.home!, address!.countryCode)}
              />
            </>
          )}
        </div>
      </div>

      <div className={`${CSSBlock}__details`}>
        {customer?.address?.countryCode != null &&
          getRegionAddress(customer.address.countryCode) &&
          Object.entries(getRegionAddress(customer.address.countryCode)!.address).map(([key, val], i) => (
            <div className={`${CSSBlock}__item`} key={i}>
              <div className={`${CSSBlock}__item-key`}>{val.label}</div>
              <div
                className={`${CSSBlock}__item-value ${
                  val.validate != null && val.validate(address?.home?.[key]) ? '' : `${CSSBlock}__item-value--error`
                }`}>
                {editMode ? editModeInput(key, val) : customer.address?.home?.[key] ?? '-'}
              </div>
            </div>
          ))}

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Region</div>
          <div className={`${CSSBlock}__item-value`}>
            {customer?.address?.countryCode != null && customer.address.countryCode}
          </div>
        </div>
      </div>

      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default CustomerAddress;
