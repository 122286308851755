import React from 'react';

import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import { DemmiButtonSize } from '@demmi-ui/Buttons/buttons.helper';
import DemmiInput from '@demmi-ui/Input';

import { SidebarManagedButton, SidebarProps, isSidebarSelfManaged, isSidebarTitleButton } from './Sidebar';
import SidebarButton from './SidebarButton';

const SidebarDesktop: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar-desktop';
  const { fixed = true, active, title, titleButtons, onClick, onSearch, searchPlaceholder } = props;

  const isCustomButton = isSidebarSelfManaged(props);
  const buttons = isCustomButton ? props.customButtons : props.buttons;

  const headerView = () => (
    <div className={`${CSSBlock}__header`}>
      <div className={`${CSSBlock}__title`}>
        {title}
        {titleButtons && (
          <div className={`${CSSBlock}__title-buttons`}>
            {titleButtons.map((titleButton, i) =>
              isSidebarTitleButton(titleButton) ? (
                <DemmiIconButton
                  key={i}
                  faIcon={titleButton.faIcon}
                  onClick={titleButton.onClick}
                  size={DemmiButtonSize.S}
                />
              ) : (
                titleButton
              )
            )}
          </div>
        )}
      </div>
      {onSearch && (
        <div className={`${CSSBlock}__search`}>
          <DemmiInput onChangeValue={onSearch} invert type="text" placeholder={searchPlaceholder} />
        </div>
      )}
    </div>
  );

  const buttonsView = () => (
    <div className={`${CSSBlock}__buttons`}>
      {buttons.map((button, i) =>
        isCustomButton ? (
          [
            ...(i > 0 && props.customButtonsDivider ? [props.customButtonsDivider] : []),
            <SidebarButton element={button as JSX.Element} active={i === active} key={i} onClick={() => onClick(i)} />,
          ]
        ) : (
          <SidebarButton
            text={(button as SidebarManagedButton).text}
            faIcon={(button as SidebarManagedButton).faIcon}
            active={i === active}
            key={i}
            onClick={() => onClick(i)}
          />
        )
      )}
    </div>
  );

  return (
    <div
      className={`${CSSBlock} ${fixed ? `${CSSBlock}--fixed` : ``} ${
        isCustomButton ? `${CSSBlock}--custom-buttons` : ``
      }`}>
      <div className={`${CSSBlock}__invisible-contents`}>
        <div className={`${CSSBlock}__invisible-contents-text`}>Expand</div>
      </div>
      {headerView()}
      {buttonsView()}
    </div>
  );
};

export default SidebarDesktop;
