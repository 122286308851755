import { UserCredential } from 'firebase/auth';

import { FSUserDoc } from '@models/customer.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface UserState {
  cred: UserCredential | undefined;
  customer: FSUserDoc | undefined;
  isFetchingCustomer: boolean;
}

const initialState: UserState = {
  cred: undefined,
  customer: undefined,
  isFetchingCustomer: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder.addCase('LOGOUT', state => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    clearCred: state => {},
    storeCred: (state, action: PayloadAction<UserCredential>) => {},
    setCustomer: (state, action: PayloadAction<FSUserDoc | undefined>) => {
      state.customer = action.payload;
    },
    setIsFetchingCustomer: (state, action: PayloadAction<boolean>) => {
      state.isFetchingCustomer = action.payload;
    },
  },
});

export const { clearCred, storeCred, setCustomer, setIsFetchingCustomer } = userSlice.actions;

export const selectUserCred = (state: RootState) => state.user.cred;
export const selectCustomer = (state: RootState) => state.user.customer;
export const selectIsFetchingCustomer = (state: RootState) => state.user.isFetchingCustomer;

export default userSlice.reducer;
