import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import AppTheme from '@core/AppTheme';
import { useAppSelector } from '@core/hooks/appHooks';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectCustomer } from '@core/store/userSlice/userSlice';
import { isCustomerSetupComplete } from '@helpers/customer.helper';
import { useAuth } from '@providers/authProvider';

const UnprotectedRoute: React.FC = () => {
  const { user } = useAuth();
  const customer = useAppSelector(selectCustomer);
  const outlet = useOutlet();

  return customer && user ? (
    !isCustomerSetupComplete(customer) ? (
      <Navigate to={routingPaths[RoutingViews.USER_SETUP]} />
    ) : (
      <Navigate to={routingPaths[RoutingViews.HOME]} />
    )
  ) : (
    <React.Fragment>
      <AppTheme isLogin={false} />
      {outlet}
    </React.Fragment>
  );
};
export default UnprotectedRoute;
