import { isValidPhoneNumber } from 'react-phone-number-input';

import { FSUserDoc } from '@models/customer.model';

export const screenValidation = [];

export const onClickNext = [
  // Welcome screen
  () => {},

  // Region screen
  (
    customer: FSUserDoc,
    setCustomerDetails: React.Dispatch<React.SetStateAction<FSUserDoc | undefined>>,
    region: string
  ) => {
    setCustomerDetails({
      ...customer,
      address: { ...customer.address, countryCode: region },
    });
  },

  // Your Details screen
  (
    customer: FSUserDoc,
    setCustomerDetails: React.Dispatch<React.SetStateAction<FSUserDoc | undefined>>,
    title: string,
    firstName: string,
    lastName: string,
    nickname: string
  ) => {
    setCustomerDetails({
      ...customer,
      title,
      firstName,
      lastName,
      nickname,
    });
  },

  // Contact details screen
  () => {},

  // You're all set screen
  () => {},

  // Processing screen
  () => {},
];

export const isScreen01Valid = (region: string) => {
  return ['US', 'GB'].includes(region);
};
export const isScreen02Valid = (title: string, firstName: string, lastName: string, nickname: string) => {
  return title.length >= 2 && firstName.length >= 3 && lastName.length >= 3 && nickname.length >= 2;
};
export const isScreen03Valid = (contactNumber: string) => {
  return isContactNumberValid(contactNumber);
};
const isContactNumberValid = (num: string) => isValidPhoneNumber(num);
