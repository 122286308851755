import React, { useState } from 'react';

import Logo from '@assets/demmi-logo.svg';

interface LoadingGlobalProps {
  isMounted: boolean;
  isLogin?: boolean;
}

const LoadingGlobal: React.FC<LoadingGlobalProps> = ({
  isMounted,
  isLogin,
}) => {
  const CSSBlock = 'loading-overlay';
  const [hide, setHide] = useState(false);
  const mountedStyle = { animation: 'globalLoadingIn 200ms ease-in-out 0s' };
  const unmountedStyle = {
    animation: 'globalLoadingOut 400ms ease-in-out 0s',
  };

  return (
    <div
      className={`${CSSBlock} ${hide ? `${CSSBlock}--hide` : ''} ${
        isLogin ? `${CSSBlock}--login-page` : ''
      }`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__logo`}>
        <div className={`${CSSBlock}__loading-indicator`}></div>
        <div className={`${CSSBlock}__title`}>
          <img src={Logo} />
        </div>
        <div className={`${CSSBlock}__subtitle`}>
          <strong>The</strong> Natural Marketplace
        </div>
      </div>
    </div>
  );
};

export default LoadingGlobal;
