import { format } from 'date-fns';
import React from 'react';

import { Order } from '@models/orders.model';

interface Props {
  CSSBlock: string;
  selectedOrder: Order | undefined;
}

const OrderHistorySidebarActiveItemMobileView: React.FC<Props> = ({ selectedOrder, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}__sidebar-active-item`;

  return selectedOrder ? (
    <div className={`${CSSBlock}`}>
      <span className={`${CSSBlock}__heading`}>{`${selectedOrder.orderID}`}</span>
      <span className={`${CSSBlock}__description`}>{format(selectedOrder!.orderDate!, 'dd MMM yyyy, HH:mm:ss')}</span>
    </div>
  ) : (
    <></>
  );
};

export default OrderHistorySidebarActiveItemMobileView;
