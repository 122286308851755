import { Loader } from '@googlemaps/js-api-loader';
import { DemmiLogType, Logger } from '@subhanhabib/demmilib';

const loader = new Loader({
  apiKey: process.env.DEMMI_GC_MAPS_API_KEY!,
  version: 'weekly',
});

loader.load().then(async () => {});

/// <reference types="@types/google.maps" />

export const locationPermissionsCheck = (): Promise<PermissionStatus> => {
  return navigator.permissions.query({ name: 'geolocation' }).then((result: PermissionStatus) => {
    Logger({ objs: { result } }, locationPermissionsCheck);
    if (result.state === 'granted') {
      // OK
    } else if (result.state === 'prompt') {
    } else {
      // denied
    }
    return result;
  });
};

export const getUserLocation = (): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      Logger({ objs: { coords } }, getUserLocation);
      resolve([coords.latitude, coords.longitude]);
    }),
      (err: any) => {
        alert('Failed to get user location');
        Logger({ messages: ['Failed to get user location'], objs: { err }, type: DemmiLogType.error }, getUserLocation);
        reject();
      };
  });
};

export interface UserRegion {
  city?: string;
  county?: string;
  country?: string;
  countryCode?: string;
}

export const getRegionFromLatLng = async (lat: number, lng: number) => {
  Logger({ objs: { lat, lng } }, getRegionFromLatLng);
  await loader.load();
  const geocoder = new google.maps.Geocoder();

  const { results } = await geocoder.geocode({ location: { lat, lng } });
  Logger({ objs: { results } }, getRegionFromLatLng);

  if (results.length > 0) {
    const userRegion = results[0].address_components.reduce((acc: UserRegion, component) => {
      if (component.types.includes('postal_town')) acc.city = component.long_name;
      else if (component.types.includes('administrative_area_level_2')) acc.county = component.long_name;
      else if (component.types.includes('country')) {
        acc.country = component.long_name;
        acc.countryCode = component.short_name;
      }
      return acc;
    }, {});
    return userRegion;
  }
  return undefined;
};
