export enum ThemeModeConstants {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export type ThemeMode =
  | ThemeModeConstants.LIGHT
  | ThemeModeConstants.DARK
  | ThemeModeConstants.SYSTEM;

export enum CSSVARS {
  THEME_BG_POS = '--theme-bg-pos',
}

export const setCSSVar = (cssVar: CSSVARS, value: number | string) =>
  document.documentElement.style.setProperty(cssVar, value.toString());

export interface RGBA {
  r: number;
  g: number;
  b: number;
  a: number;
}
export interface HSL {
  h: number;
  s: number;
  l: number;
}
export const isHSL = (v: any): v is HSL => {
  return (
    (v as HSL).h !== undefined &&
    (v as HSL).s !== undefined &&
    (v as HSL).l !== undefined
  );
};

export const colourToRGBA = (colour: number): RGBA => {
  colour >>>= 0;
  const b = colour & 0xff,
    g = (colour & 0xff00) >>> 8,
    r = (colour & 0xff0000) >>> 16,
    a = ((colour & 0xff000000) >>> 24) / 255;
  return {
    r,
    g,
    b,
    a,
  };
};

export const colourToRGBAString = (colour: number): string => {
  const { r, g, b, a } = colourToRGBA(colour);
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
