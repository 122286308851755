import React from 'react';

import Logo from '@assets/demmi-logo.svg';
import DemmiCard from '@demmi-ui/Card/Card';

import OAuthButton from './OAuthButton';

interface Props {
  onClickGoogle: () => void;
  onClickApple: () => void;
}

const CardAuthentication: React.FC<Props> = ({ onClickGoogle, onClickApple }) => {
  const CSSBlock = 'card-authentication';

  return (
    <div className={`${CSSBlock}__wrapper`}>
      <DemmiCard className={`${CSSBlock}`}>
        <div className={`${CSSBlock}__title`}>
          <img src={Logo} />
          <span>Customer Portal</span>
        </div>

        <div className={`${CSSBlock}__oauth-area`}>
          <div className={`${CSSBlock}__description`}>Select an account to sign in with</div>
          <div className={`${CSSBlock}__oauth-buttons`}>
            <OAuthButton onClick={onClickGoogle} icon="fa-google" text="Google"></OAuthButton>
            <OAuthButton onClick={onClickApple} icon="fa-apple" text="Apple"></OAuthButton>
          </div>
        </div>
      </DemmiCard>
    </div>
  );
};

export default CardAuthentication;
