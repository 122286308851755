import React from 'react';
import { Navigate, useOutlet } from 'react-router-dom';

import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import AppTheme from '@core/AppTheme';
import { useAppSelector } from '@core/hooks/appHooks';
import { RoutingViews, routingPaths } from '@core/services/routingService';
import { selectCustomer, selectIsFetchingCustomer } from '@core/store/userSlice/userSlice';
import { isCustomerSetupComplete } from '@helpers/customer.helper';
import { useAuth } from '@providers/authProvider';

const AccountSetupRoute: React.FC = () => {
  const { user } = useAuth();
  const outlet = useOutlet();
  const customer = useAppSelector(selectCustomer);
  const isFetchingCustomer = useAppSelector(selectIsFetchingCustomer);

  return isFetchingCustomer ? (
    <LoadingGlobal isMounted={true} />
  ) : !customer ? (
    <Navigate to="/" />
  ) : isCustomerSetupComplete(customer) ? (
    <Navigate to={routingPaths[RoutingViews.HOME]} />
  ) : user ? (
    <>
      <React.Fragment>
        <AppTheme isLogin={false} />
        {outlet}
      </React.Fragment>
    </>
  ) : (
    <Navigate to="/" />
  );
};
export default AccountSetupRoute;
