import { format } from 'date-fns';
import React from 'react';

import AsyncImage from '@components/asyncImage/AsyncImage';
import { getCurrencySymbolFromCurrencyCode } from '@helpers/currency.helper';
import { Order } from '@models/orders.model';

interface Props {
  CSSBlock: string;
  order: Order;
}

const OrderHistorySidebarButton: React.FC<Props> = ({ order, CSSBlock: parentCSS }) => {
  const CSSBlock = `${parentCSS}-sidebar-button`;

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__farms`}>
        {Object.entries(order.farms).map(([farmID, farm]) => (
          <div className={`${CSSBlock}__farm`} style={{ '--local-farm-hue': farm.themeHue }}>
            <div className={`${CSSBlock}__farm-thumbnail`}>
              <AsyncImage farmID={farmID} isFarm src={farm.image} />
            </div>
            <div className={`${CSSBlock}__farm-content`}>
              <div className={`${CSSBlock}__farm-name`}>{farm.name}</div>
              <div className={`${CSSBlock}__farm-cost`}>
                {getCurrencySymbolFromCurrencyCode(farm.cost.currencyCode)}
                {Number(farm.cost.gross).toFixed(2)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={`${CSSBlock}__footer`}>
        <span className={`${CSSBlock}__date`}>{format(order.orderDate!, 'dd MMM yyyy, HH:mm:ss')}</span>
        <span className={`${CSSBlock}__total-cost`}>
          {getCurrencySymbolFromCurrencyCode(order.cost.currencyCode)}
          {Number(order.cost.gross).toFixed(2)}
        </span>
      </div>
    </div>
  );
};

export default OrderHistorySidebarButton;
