import { DocumentData, QueryDocumentSnapshot, Unsubscribe, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';

import { FSOrder, FSOrderReview, Order } from '@models/orders.model';
import { FSCollections } from '@providers/firestoreProvider';
import { Logger } from '@subhanhabib/demmilib';

import { getDateFromFirestore } from '../networkService';
import { parseToOrder } from './_helper';
import { getOrdersQuery, orderQuery, orderReviewsFarmQuery } from './_queries';

export const getOrders = async (customerID: string): Promise<Order[]> => {
  const querySnapshot = await getDocs(getOrdersQuery(customerID));
  let orders: Order[] = [];

  querySnapshot.forEach(async (doc: QueryDocumentSnapshot<FSOrder>) => {
    orders.push(parseToOrder(doc));
  });

  Logger({ objs: { orders } }, getOrders);
  return orders;
};

export const listenToOrder = async (orderDocID: string, callback: (order?: Order) => void): Promise<Unsubscribe> => {
  return onSnapshot(orderQuery(orderDocID), async querySnapshot => {
    if (querySnapshot.exists()) {
      callback(parseToOrder(querySnapshot));
    } else {
      Logger({ messages: ['No such document'] }, listenToOrder);
      callback(undefined);
    }
  });
};

export const listenToOrderReview = async (
  orderID: string,
  farmID: string,
  callback: (review?: FSOrderReview) => void
): Promise<Unsubscribe | undefined> => {
  const docSnap = await getDoc(doc(FSCollections.Orders, orderID));
  if (!docSnap.exists()) {
    return;
  }
  return onSnapshot(orderReviewsFarmQuery(farmID, docSnap.data().orderID), async querySnapshot => {
    if (querySnapshot.empty) callback(undefined);
    else {
      const review = querySnapshot.docs[0].data();
      review.id = querySnapshot.docs[0].id;
      review.timestamp = getDateFromFirestore(querySnapshot.docs[0].data()['timestamp'] as DocumentData);
      callback(review);
    }
  });
};
