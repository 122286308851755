import { Coupon } from '@models/coupons.model';
import { FSOrderFarmCoupon } from '@models/orders.model';

/**
 * TYPES
 */

export enum CouponTypes {
  coupon_discount_fixed = 'Fixed Discount',
  coupon_discount_percent = 'Percentage Discount',
  coupon_service_charge = 'No Service Fee',
  invalid = 'Invalid Coupon Type',
}

export const getCouponType = (type: string): CouponTypes => {
  switch (type) {
    case 'coupon_discount_fixed':
      return CouponTypes.coupon_discount_fixed;
    case 'coupon_discount_percent':
      return CouponTypes.coupon_discount_percent;
    case 'coupon_service_charge':
      return CouponTypes.coupon_service_charge;
    default:
      return CouponTypes.invalid;
  }
};

export const getCouponTypeKey = (type: CouponTypes): string => {
  return Object.keys(CouponTypes)[Object.values(CouponTypes).indexOf(type)];
};

export const getCouponAmount = (currencySymbol: string, coupon: Coupon): string => {
  switch (coupon.type) {
    case CouponTypes.coupon_discount_fixed:
      return `${currencySymbol}${coupon.amount}`;
    case CouponTypes.coupon_discount_percent:
      return `${coupon.amount * 100}%`;
    case CouponTypes.coupon_service_charge:
      return CouponTypes.coupon_service_charge;
    default:
      return coupon.amount.toString();
  }
};

export const getOrderCouponAmount = (currencySymbol: string, coupon: FSOrderFarmCoupon) => {
  if (coupon.type == CouponTypes.coupon_discount_percent) {
    return `${coupon.amount * 100}%`;
  } else if (coupon.type == CouponTypes.coupon_discount_fixed) {
    return `${currencySymbol}${Number(coupon.amount).toFixed(2)}`;
  } else {
    return coupon.amount;
  }
};
