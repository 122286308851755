import { CollectionReference, DocumentData, collection } from 'firebase/firestore';
import { ReactNode, createContext, useContext } from 'react';

import { FSCollectionNames } from '@core/services/networkService/networkService';
import { FSUserModel } from '@models/customer.model';
import { FSInviteCode } from '@models/inviteCodes.model';
import { FSOrder, FSOrderReview } from '@models/orders.model';

import { db } from '../firebase';

export interface FirestoreProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>({} as UserContextState);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface FirestoreContextModel {
  // farmsCollection: CollectionReference<FSFarm>;
  // productsCollection: CollectionReference<FSProduct>;
  // variantsCollection: (pathSegments?: string[]) => CollectionReference<FSProductVariant>;
}

export const FirestoreContext = createContext<FirestoreContextModel>({} as FirestoreContextModel);

export function useFirestore(): FirestoreContextModel {
  return useContext(FirestoreContext);
}

export const createCollection =
  <T = DocumentData,>(collectionName: string) =>
  (pathSegments: string[] = []) => {
    return collection(db, collectionName, ...pathSegments) as CollectionReference<T>;
  };

export const FSCollections = {
  Orders: createCollection<FSOrder>(FSCollectionNames.ORDERS)(),
  OrderReviews: createCollection<FSOrderReview>(FSCollectionNames.FARMS),
  InviteCodes: createCollection<FSInviteCode>(FSCollectionNames.INVITE_CODES)(),
  Users: createCollection<FSUserModel>(FSCollectionNames.USERS)(),
};

export const FirestoreProvider = ({ children }: FirestoreProviderProps): JSX.Element => {
  const values = {
    usersCollection: FSCollections.Users,
  };

  return <FirestoreContext.Provider value={values}>{children}</FirestoreContext.Provider>;
};
