import React, { useEffect, useState } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneInput, { Country } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

import ElementLoadingIndicator from '@components/loadingIntro/ElementLoadingIndicator';
import { useAppDispatch } from '@core/hooks/appHooks';
import { FSCustomer } from '@core/services/networkService/customer/customer';
import { setCustomer } from '@core/store/userSlice/userSlice';
import DemmiButtonSwitch from '@demmi-ui/Buttons/ButtonSwitch';
// import DemmiButtonSwitch from '@demmi-ui/Buttons/ButtonSwitch';
import DemmiIconButton from '@demmi-ui/Buttons/IconButton';
import DemmiInput from '@demmi-ui/Input';
import DemmiInputPhone from '@demmi-ui/InputPhone';
import DemmiItemSwitch from '@demmi-ui/ItemSwitch';
import Menu from '@demmi-ui/Menu/Menu';
import MenuTriggerButton from '@demmi-ui/Menu/MenuTriggerButton';
import { MenuItem } from '@demmi-ui/Menu/MenuTypes';
import {
  customerGenders,
  customerTitles,
  getCustomerGender,
  isCustomerDetailsValid,
  isCustomerFirstNameValid,
  isCustomerLasttNameValid,
  isCustomerNicknameValid,
} from '@helpers/customer.helper';
import { FSUserDoc } from '@models/customer.model';
import { DemmiUI } from '@subhanhabib/demmilib';

interface Props {
  customer: FSUserDoc;
}

const CustomerDetails: React.FC<Props> = ({ customer }) => {
  const CSSBlock = 'customer-details-block';
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [gender, setGender] = useState(customer.gender);
  const [title, setTitle] = useState(customer.title);
  const [firstName, setFirstName] = useState(customer.firstName);
  const [lastName, setLastName] = useState(customer.lastName);
  const [nickname, setNickname] = useState(customer.nickname);
  const [contactNumber, setContactNumber] = useState(
    parsePhoneNumber(customer.contactNumber, customer.address?.countryCode as Country)?.formatInternational()
  );

  useEffect(() => {
    setIsValid(
      isCustomerDetailsValid({
        title,
        firstName,
        lastName,
        nickname,
        contactNumber: contactNumber ?? '',
        address: customer.address,
      })
    );
  }, [title, firstName, lastName, nickname, contactNumber]);

  useEffect(() => {}, [firstName]);

  const getMenuContent = () => {
    return {
      sections: [
        {
          items: customerTitles.map(
            t =>
              ({
                id: t,
                text: t,
                item: t,
              } as MenuItem<string>)
          ),
        },
      ],
    };
  };

  const updateCustomer = async () => {
    setIsLoading(true);
    await FSCustomer.profile
      .updateDetails(customer.docID, title, firstName, lastName, nickname, contactNumber ?? '', gender)
      .then(() => {
        FSCustomer.doc.getByDocID(customer.docID).then(customer => {
          dispatch(setCustomer(customer));
          setIsLoading(false);
        });
      });
  };

  const onSave = async () => {
    if (isValid) {
      await updateCustomer();
      setEditMode(false);
    }
  };

  const onCancel = () => {
    setGender(customer.gender);
    setTitle(customer.title);
    setFirstName(customer.firstName);
    setLastName(customer.lastName);
    setNickname(customer.nickname);
    setContactNumber(
      parsePhoneNumber(customer.contactNumber, customer.address.countryCode as Country)?.formatInternational()
    );
    setEditMode(false);
  };

  return (
    <div
      className={`${CSSBlock} ${editMode ? `${CSSBlock}--edit-mode` : ``} ${isLoading ? `${CSSBlock}--loading` : ``}`}>
      <div className={`${CSSBlock}__section-title`}>
        Your Account Details
        <div className={`${CSSBlock}__control-buttons`}>
          {!editMode && <DemmiIconButton faIcon="fa-pencil" onClick={() => setEditMode(!editMode)} />}
          {editMode && (
            <>
              <DemmiIconButton faIcon="fa-cancel" onClick={() => onCancel()} />
              <DemmiIconButton disabled={!isValid} faIcon="fa-save" onClick={() => onSave()} />
            </>
          )}
        </div>
      </div>

      <div className={`${CSSBlock}__details`}>
        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Title</div>
          <div className={`${CSSBlock}__item-value`}>
            {editMode ? (
              <Menu<string>
                content={getMenuContent()}
                triggerEl={<MenuTriggerButton text={customer.title} styles={{ width: '100%' }} />}
                onClick={t => setTitle(t)}
              />
            ) : (
              customer.title
            )}
          </div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>First Name</div>
          <div
            className={`${CSSBlock}__item-value ${
              isCustomerFirstNameValid(firstName) ? `` : `${CSSBlock}__item-value--error`
            }`}>
            {editMode ? (
              <>
                <DemmiInput
                  value={firstName}
                  onChangeValue={val => setFirstName(val)}
                  type="text"
                  error={!isCustomerFirstNameValid(firstName)}
                />
                <span className={`${CSSBlock}__item-value-required`}>Required Field *</span>
              </>
            ) : (
              customer.firstName
            )}
          </div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Last Name</div>
          <div
            className={`${CSSBlock}__item-value ${
              isCustomerLasttNameValid(lastName) ? `` : `${CSSBlock}__item-value--error`
            }`}>
            {editMode ? (
              <>
                <DemmiInput
                  value={lastName}
                  onChangeValue={val => setLastName(val)}
                  type="text"
                  error={!isCustomerLasttNameValid(lastName)}
                />
                <span className={`${CSSBlock}__item-value-required`}>Required Field *</span>
              </>
            ) : (
              customer.lastName
            )}
          </div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Nickname</div>
          <div
            className={`${CSSBlock}__item-value ${
              isCustomerNicknameValid(nickname) ? `` : `${CSSBlock}__item-value--error`
            }`}>
            {editMode ? (
              <>
                <DemmiInput
                  value={nickname}
                  onChangeValue={val => setNickname(val)}
                  type="text"
                  error={!isCustomerNicknameValid(nickname)}
                />
                <span className={`${CSSBlock}__item-value-required`}>Required Field *</span>
              </>
            ) : (
              customer.nickname
            )}
          </div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Email</div>
          <div className={`${CSSBlock}__item-value`}>{customer.email}</div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Contact Number</div>
          <div className={`${CSSBlock}__item-value`}>
            {editMode ? (
              <PhoneInput
                inputComponent={DemmiInputPhone}
                country={customer.address.countryCode as Country}
                value={contactNumber}
                onChange={e => setContactNumber(e?.toString() ?? '')}
              />
            ) : (
              <>{customer.contactNumber}</>
            )}
          </div>
        </div>

        <div className={`${CSSBlock}__item`}>
          <div className={`${CSSBlock}__item-key`}>Gender</div>
          {/* <DemmiButtonSwitch buttons={['HEY']} onClick={() => {}} /> */}
          {/* <DemmiItemSwitch items={[<div>A</div>]} onClick={() => {}} /> */}
          {/* <DemmiUI.ItemSwitch items={[<div />]} onClick={() => {}} /> */}
          <div className={`${CSSBlock}__item-value`}>
            {editMode ? (
              <DemmiButtonSwitch
                buttons={Object.values(customerGenders)}
                initialIndex={customer.gender}
                onClick={i => setGender(i)}
              />
            ) : customer.gender != null ? (
              getCustomerGender(customer.gender)
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>

      <ElementLoadingIndicator isLoading={isLoading} />
    </div>
  );
};

export default CustomerDetails;
