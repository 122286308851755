import { NavigateFunction, To } from 'react-router-dom';

import { popNetworkRequestUUID, pushNetworkRequestUUID, setGlobalContentMounted } from '@core/store/appSlice/appSlice';
import { store } from '@core/store/store';
import { Logger } from '@subhanhabib/demmilib';

export const demmiRequest = async <T>(
  request: Promise<T>,
  options?: { mockDelay?: boolean; logMessages?: string[] }
): Promise<T> => {
  const requestUUID = crypto.randomUUID();
  store.dispatch(pushNetworkRequestUUID(requestUUID));
  return request
    .catch(e => {
      Logger({ objs: { logMessages: options?.logMessages, request, e } }, demmiRequest);
      setTimeout(() => store.dispatch(popNetworkRequestUUID(requestUUID)), 200);
      return Promise.reject(e);
    })
    .then(async res => {
      if (options?.mockDelay) await new Promise(res => setTimeout(res, 50000)); // fake sleep
      // Delay by 100ms to allow chained requests to keep loading indicator active
      setTimeout(() => store.dispatch(popNetworkRequestUUID(requestUUID)), 200);
      return res;
    });
};

export const navigateTransitioned = ({
  dispatch,
  to,
  navigate,
  search,
}: {
  dispatch: any;
  to: To;
  navigate: NavigateFunction;
  search?: { [key: string]: string };
}) => {
  dispatch(setGlobalContentMounted(false));
  setTimeout(() => {
    navigate(search ? routeWithSearch({ route: to.toString(), search }) : to, {
      replace: true,
    });
    dispatch(setGlobalContentMounted(true));
  }, 200);
};

export const routeWithSearch = ({ route, search }: { route: string; search: { [key: string]: string } }) => {
  return `${route}?${Object.entries(search)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;
};
