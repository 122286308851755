/**
 * Order Status and Order Farm Status
 */
import { Order } from '../models/orders.model.js';

export enum OrderStatus {
  CREATED = 'created',
  PAYMENT_SUCCESSFUL = 'payment-successful',
  PAYMENT_FAILED = 'payment-failed',
  READY = 'ready',
  COMPLETED = 'completed',
  CANCELLED_USER = 'cancelled-user',
  CANCELLED_AUTO = 'cancelled-auto',
  UNKNOWN = '-',
}

export enum OrderFarmStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in-progress',
  READY_FOR_COLLECTION = 'ready-for-collection',
  COLLECTED = 'collected',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded',
  UNKNOWN = '-',
}

export const getOrderFarmStatusText = (status: OrderFarmStatus) => {
  switch (status) {
    case OrderFarmStatus.CREATED:
      return 'Created';
    case OrderFarmStatus.IN_PROGRESS:
      return 'In Progress';
    case OrderFarmStatus.READY_FOR_COLLECTION:
      return 'Ready for Collection';
    case OrderFarmStatus.COLLECTED:
      return 'Collected';
    case OrderFarmStatus.CANCELLED:
      return 'Cancelled';
    case OrderFarmStatus.REFUNDED:
      return 'Refunded';
    default:
      return '-';
  }
};

export const getOrderStatus = (status?: string): OrderStatus | undefined => {
  const match = Object.entries(OrderStatus).find(([_, val]) => val === status);
  return match ? match[1] : undefined;
};

export const getOrderFarmStatus = (status?: string): OrderFarmStatus | undefined => {
  const match = Object.entries(OrderFarmStatus).find(([_, val]) => val === status);
  return match ? match[1] : undefined;
};

export const getStatusFromOrder = (farmID: string, order: Order) => {
  const status = order.timeline.findLast(update => !!update.statusUpdate && farmID === update.farmID)?.statusUpdate;
  return status ? getOrderFarmStatusText(status) : 'Status Unknown';
};
