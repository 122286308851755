import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum DemmiButtonSize {
  XS = 'x-small',
  S = 'small',
  M = 'medium',
  L = 'large',
}

export interface DemmiButtonBaseProps {
  faIcon?: string;
  icon?: IconDefinition;
  disabled?: boolean;
  className?: string;
  size?: DemmiButtonSize;
  tabIndex?: number;
  invert?: boolean;
  styles?: React.CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}
