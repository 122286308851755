import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  cardIndex: number;
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  title: string | JSX.Element;
}
const ScreenWrapper: React.FC<Props> = ({
  cardIndex,
  isMounted,
  mountedStyle,
  unmountedStyle,
  title,
  children,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--${cardIndex}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>{title}</div>
      <div className={`${CSSBlock}__content`}>{children}</div>
    </div>
  );
};

export default ScreenWrapper;
