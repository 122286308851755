export interface FBAuthProviderInfo {
  providerID: string;
  uid: string;
  email?: string;
}

export enum FBAuthProviderID {
  google = 'google.com',
  apple = 'apple.com',
}

export enum FBAuthProvider {
  google = 'google',
  apple = 'apple',
}

export const getFBAuthProviderByID = (providerID: string) => {
  if (providerID.includes('apple')) return FBAuthProvider.apple;
  if (providerID.includes('google')) return FBAuthProvider.google;
  return undefined;
};

export const isAuthApple = (provider: FBAuthProviderInfo) => provider.providerID.includes('apple');

export const isAuthAppleObscured = (provider: FBAuthProviderInfo) =>
  provider.providerID.includes('apple') && provider.email?.includes('privaterelay');

export const getAuthProviderName = (provider: FBAuthProviderInfo) =>
  provider.providerID.includes('apple') ? 'Apple' : provider.providerID.includes('google') ? 'Google' : '';
