import React, { useEffect, useRef, useState } from 'react';

interface DemmiItemSwitchProps {
  items: JSX.Element[];
  initialIndex?: number;
  onClick: (i: number) => void;
}

const DemmiItemSwitch: React.FC<DemmiItemSwitchProps> = ({ items, initialIndex, onClick }) => {
  const CSSBlock = 'demmi-ui-item-switch';
  const indicator = useRef<HTMLSpanElement>(null);
  const switchContainer = useRef<HTMLDivElement>(null);
  const initialButton = useRef<HTMLButtonElement>(null);
  const [active, setActive] = useState(initialIndex);

  useEffect(() => {
    setActive(initialIndex);
  }, [initialIndex]);

  const updateIndicatorPos = (x: number, width: number) => {
    if (indicator.current) {
      indicator.current.style.setProperty('left', `${x}px`);
      indicator.current.style.setProperty('width', `${width}px`);
    }
  };

  const onItemClick = (i: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
    onClick(i);
    setActive(i);
    if (switchContainer.current) {
      const switchBounds = switchContainer.current?.getBoundingClientRect();
      const { x, width } = (e.target as HTMLButtonElement).getBoundingClientRect();
      updateIndicatorPos(x - switchBounds.x, width);
    }
  };

  useEffect(() => {
    if (indicator.current && initialButton.current && switchContainer.current) {
      const switchBounds = switchContainer.current?.getBoundingClientRect();
      const { x, width } = initialButton.current.getBoundingClientRect();
      updateIndicatorPos(x - switchBounds.x, width);
    }
  }, []);

  return (
    <div className={`${CSSBlock}`} ref={switchContainer}>
      {items.map((item, i) => (
        <button
          className={`${CSSBlock}__item ${active === i ? `${CSSBlock}__item--active` : ''}`}
          key={i}
          {...(initialIndex === i ? { ref: initialButton } : {})}
          onClick={onItemClick(i)}>
          <span className={`${CSSBlock}__item-content`}>{item}</span>
        </button>
      ))}
      <span className={`${CSSBlock}__indicator`} ref={indicator}></span>
    </div>
  );
};

export default DemmiItemSwitch;
