export const REGION_US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const REGION_UK_COUNTIES = {
  ENGLAND: [
    'Avon',
    'Bedfordshire',
    'Berkshire',
    'Buckinghamshire',
    'Cambridgeshire',
    'Cheshire',
    'Cleveland',
    'Cornwall',
    'Cumbria',
    'Derbyshire',
    'Devon',
    'Dorset',
    'Durham',
    'East Sussex',
    'Essex',
    'Gloucestershire',
    'Hampshire',
    'Herefordshire',
    'Hertfordshire',
    'Isle of Wight',
    'Kent',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'London',
    'Merseyside',
    'Middlesex',
    'Norfolk',
    'Northamptonshire',
    'Northumberland',
    'North Humberside',
    'North Yorkshire',
    'Nottinghamshire',
    'Oxfordshire',
    'Rutland',
    'Shropshire',
    'Somerset',
    'South Humberside',
    'South Yorkshire',
    'Staffordshire',
    'Suffolk',
    'Surrey',
    'Tyne and Wear',
    'Warwickshire',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Wiltshire',
    'Worcestershire',
  ],
  WALES: [
    'Clwyd',
    'Dyfed',
    'Gwent',
    'Gwynedd',
    'Mid Glamorgan',
    'Powys',
    'South Glamorgan',
    'West Glamorgan',
  ],
  SCOTLAND: [
    'Aberdeenshire',
    'Angus',
    'Argyll',
    'Ayrshire',
    'Banffshire',
    'Berwickshire',
    'Bute',
    'Caithness',
    'Clackmannanshire',
    'Dumfriesshire',
    'Dunbartonshire',
    'East Lothian',
    'Fife',
    'Inverness-shire',
    'Kincardineshire',
    'Kinross-shire',
    'Kirkcudbrightshire',
    'Lanarkshire',
    'Midlothian',
    'Moray',
    'Nairnshire',
    'Orkney',
    'Peeblesshire',
    'Perthshire',
    'Renfrewshire',
    'Ross-shire',
    'Roxburghshire',
    'Selkirkshire',
    'Shetland',
    'Stirlingshire',
    'Sutherland',
    'West Lothian',
    'Wigtownshire',
  ],
  NORTHERN_IRELAND: [
    'Antrim',
    'Armagh',
    'Down',
    'Fermanagh',
    'Londonderry',
    'Tyrone',
  ],
};
