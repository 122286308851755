import React from 'react';

interface Props {}

const ComingSoon: React.FC<Props> = () => {
  const CSSBlock = 'coming-soon';

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__title`}>.:. Coming Soon .:.</div>
      <div className={`${CSSBlock}__description`}>
        We're eagerly crafting this feature to make your experience even better! Hang tight and keep an eye out for
        updates.
      </div>
      <div className={`${CSSBlock}__description`}>Thank you for your patience and continued support!</div>
    </div>
  );
};

export default ComingSoon;
