import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faStyles } from './IconButton';
import { DemmiButtonBaseProps } from './buttons.helper';

export interface DemmiButtonProps extends DemmiButtonBaseProps {
  text: string;
}

const DemmiButton = React.forwardRef<HTMLButtonElement, DemmiButtonProps>(
  ({ text, icon, faIcon, disabled, invert, onClick, styles, size, className, tabIndex }, ref) => {
    const CSSBlock = 'demmi-ui-button';

    return (
      <button
        className={`${CSSBlock} ${size ? `${CSSBlock}--size-${size}` : ''} ${invert ? `${CSSBlock}--invert` : ''} ${
          className || ''
        }`}
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        style={styles}
        {...(tabIndex ? { tabIndex: tabIndex } : {})}>
        {icon ? (
          <span className={`${CSSBlock}__icon`}>
            <FontAwesomeIcon icon={icon} />
          </span>
        ) : (
          faIcon && (
            <span className={`${CSSBlock}__icon`}>
              <i className={faStyles.find(item => faIcon.includes(item)) ? faIcon : `fa-solid ${faIcon}`}></i>
            </span>
          )
        )}
        <span className={`${CSSBlock}__text`}>{text}</span>
      </button>
    );
  }
);

export default DemmiButton;
