import { addDoc, collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';

import { FBAuthProvider, FBAuthProviderInfo } from '@helpers/authProvider.helper';
import { FSUserDoc, FSUserModel } from '@models/customer.model';
import { FSCollections } from '@providers/firestoreProvider';
import { Logger } from '@subhanhabib/demmilib';

import { db } from '../../../../firebase';
import { activateInviteCode, getInviteCode } from '../_inviteCodes';
import { FSCollectionNames } from '../networkService';

export class FSCustomerAuth {
  static _getDocByAuth = async (authUID: string, provider: string): Promise<FSUserDoc | undefined> => {
    const q = query(FSCollections.Users, where(`fb_authProviders.${provider}.uid`, '==', authUID));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;
    if (querySnapshot.size !== 1) return;
    return {
      docID: querySnapshot.docs[0].id,
      ...(querySnapshot.docs[0].data() as FSUserModel),
    };
  };

  static _getDocByID = async (customerDocID: string): Promise<FSUserDoc | undefined> => {
    const docSnap = await getDoc(doc(db, FSCollectionNames.USERS, customerDocID));
    if (docSnap.exists()) {
      return {
        docID: docSnap.id,
        ...(docSnap.data() as FSUserModel),
      };
    } else Logger({ messages: 'No such document!' }, this._getDocByID);
    return undefined;
  };

  static _getDocID = async (authUID: string, provider: FBAuthProvider): Promise<string | undefined> => {
    Logger({ objs: { authUID, provider } }, this._getDocID);
    return this._getDocByAuth(authUID, provider).then(doc => doc?.docID);
  };

  static _getByProvider = async (authUID: string, provider: FBAuthProvider): Promise<FSUserDoc | undefined> => {
    return provider === FBAuthProvider.apple
      ? this._getByProviderApple(authUID)
      : provider === FBAuthProvider.google
      ? this._getByProviderGoogle(authUID)
      : undefined;
  };

  static _getByProviderApple = async (authUID: string): Promise<FSUserDoc | undefined> => {
    return this._getDocByAuth(authUID, 'apple');
  };

  static _getByProviderGoogle = async (authUID: string): Promise<FSUserDoc | undefined> => {
    return this._getDocByAuth(authUID, 'google');
  };

  static _createNewDoc = async (
    customerID: string,
    provider: FBAuthProvider,
    authProviderInfo: FBAuthProviderInfo,
    email: string,
    inviteCode: string
  ): Promise<boolean> => {
    Logger({ objs: { customerID, authProviderInfo, inviteCode } }, this._createNewDoc);

    // Double check if email and code match
    const code = await getInviteCode(inviteCode);
    if (code?.email != email) throw 'Invite code and email do not match!';

    await activateInviteCode(inviteCode, email);
    await addDoc(collection(db, FSCollectionNames.USERS), {
      inviteCode: inviteCode,
      // email: email,
      fb_authProviders: { [provider]: authProviderInfo },
    });

    return true;
  };
}
