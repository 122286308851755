import { REGION_UK_COUNTIES, REGION_US_STATES } from './regions.helper';

export interface RegionField {
  label: string;
  values?: string[];
  hint?: string;
  maxLength?: number;
  validate?: (str?: string) => boolean;
}

export interface RegionAddress {
  countryCode: string;
  countryLabel: string;
  address: { [key: string]: RegionField };
}

const regionField_GB: RegionAddress = {
  countryCode: 'GB',
  countryLabel: 'United Kingdom',
  address: {
    addressLine1: {
      label: 'Address Line 1',
      validate: str => str != null && str.length > 3,
    },
    addressLine2: { label: 'Address Line 2' },
    town: { label: 'Town', validate: str => str != null && str.length > 3 },
    county: {
      label: 'County',
      values: [
        ...REGION_UK_COUNTIES.ENGLAND,
        ...REGION_UK_COUNTIES.SCOTLAND,
        ...REGION_UK_COUNTIES.WALES,
        ...REGION_UK_COUNTIES.NORTHERN_IRELAND,
      ],
      validate: str => str != null && str.length > 3,
    },
    postCode: {
      label: 'Post Code',
      validate: str => str != null && str.length > 5,
    },
  },
};

const regionField_DE: RegionAddress = {
  countryCode: 'DE',
  countryLabel: 'Germany',
  address: {
    addressLine1: { label: 'Address Line 1' },
    addressLine2: { label: 'Address Line 2' },
    PLZ: { label: 'PLZ' },
    city: { label: 'Town/City' },
  },
};

const regionField_CA: RegionAddress = {
  countryCode: 'CA',
  countryLabel: 'Canada',
  address: {
    addressLine1: { label: 'Address Line 1' },
    addressLine2: { label: 'Address Line 2' },
    city: { label: 'City' },
    province: { label: 'Province' },
    postCode: { label: 'Postal Code' },
  },
};

const regionField_US: RegionAddress = {
  countryCode: 'US',
  countryLabel: 'United States',
  address: {
    addressLine1: {
      label: 'Address Line 1',
      hint: 'Street address or P.O. Box',
    },
    addressLine2: {
      label: 'Address Line 2',
      hint: 'Apt, suite, unit, building, floor, etc.',
    },
    city: { label: 'City' },
    state: { label: 'State', values: Object.values(REGION_US_STATES) },
    zipCode: { label: 'ZIP Code' },
  },
};

export const getRegionAddress = (countryCode: string): RegionAddress | null => {
  switch (countryCode) {
    case 'CA':
      return regionField_CA;
    case 'DE':
      return regionField_DE;
    case 'GB':
      return regionField_GB;
    case 'US':
      return regionField_US;
  }
  return null;
};
