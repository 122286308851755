export enum Regions {
  CA,
  GB,
  US,
}

export interface RegionCurrencyInfo {
  currency: string;
  currencySymbol: string;
  currencyCode: string;
}

export const RegionCurrencies: Map<Regions, RegionCurrencyInfo> = new Map([
  [
    Regions.CA,
    {
      currency: 'cad',
      currencySymbol: '$',
      currencyCode: 'cad',
    },
  ],
  [
    Regions.GB,
    {
      currency: 'gbp',
      currencySymbol: '£',
      currencyCode: 'gbp',
    },
  ],
  [
    Regions.US,
    {
      currency: 'usd',
      currencySymbol: '$',
      currencyCode: 'usd',
    },
  ],
]);

export const getCurrencyInfoFromRegion = (
  region: string
): RegionCurrencyInfo | undefined => {
  switch (region) {
    case 'CA':
      return RegionCurrencies.get(Regions.CA);
    case 'GB':
      return RegionCurrencies.get(Regions.GB);
    case 'US':
      return RegionCurrencies.get(Regions.US);
  }
};

export const getCurrencyInfoFromCurrencyCode = (
  code: string
): RegionCurrencyInfo | undefined => {
  switch (code) {
    case 'cad':
      return RegionCurrencies.get(Regions.CA);
    case 'gbp':
      return RegionCurrencies.get(Regions.GB);
    case 'usd':
      return RegionCurrencies.get(Regions.US);
  }
};

export const getCurrencyInfoFromCountryCode = (
  code: string
): RegionCurrencyInfo | undefined => {
  switch (code.toLowerCase()) {
    case 'ca':
      return RegionCurrencies.get(Regions.CA);
    case 'gb':
      return RegionCurrencies.get(Regions.GB);
    case 'us':
      return RegionCurrencies.get(Regions.US);
  }
};

export const getCurrencySymbolFromCurrencyCode = (currencyCode: string) =>
  getCurrencyInfoFromCurrencyCode(currencyCode)?.currencySymbol ?? '';

export const getCurrencySymbolFromCountryCode = (countryCode: string) =>
  getCurrencyInfoFromCountryCode(countryCode)?.currencySymbol ?? '';
