import { ThemeMode, ThemeModeConstants } from '@helpers/theme.helper';

export interface AppSnapshot {
  activeTab: File;
}

export enum LSKeys {
  THEME_MODE = 'THEME_MODE',
}

export class LocalStorage {
  static get(key: LSKeys): ThemeMode | undefined {
    if (key === LSKeys.THEME_MODE) {
      const val = localStorage.getItem(key);
      switch (val) {
        case ThemeModeConstants.LIGHT:
          return ThemeModeConstants.LIGHT;
        case ThemeModeConstants.DARK:
          return ThemeModeConstants.DARK;
        default:
          return ThemeModeConstants.SYSTEM;
      }
    }
  }

  static set(key: LSKeys, value: ThemeMode) {
    if (key === LSKeys.THEME_MODE) {
      localStorage.setItem(key, value as ThemeMode);
    }
  }
}
