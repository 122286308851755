import React from 'react';

import SidebarDesktop from './SidebarDesktop';
import SidebarMobile from './SidebarMobile';

export interface SidebarTitleButton {
  faIcon: string;
  onClick: () => void;
}

interface _SelfManaged extends _Base {
  customButtons: JSX.Element[];
  customButtonsDivider?: JSX.Element;
  activeItemMobileView: JSX.Element;
}

export interface SidebarManagedButton {
  id: string;
  text: string;
  faIcon?: string;
}

interface _Managed extends _Base {
  buttons: SidebarManagedButton[];
}

interface _Base {
  fixed?: boolean;
  active: number;
  onClick: (index: number) => void;
  title: string;
  titleButtons?: (SidebarTitleButton | JSX.Element)[];
  onSearch?: (val: string) => void;
  searchPlaceholder?: string;
}
export type SidebarProps = _Managed | _SelfManaged;

export const isSidebarSelfManaged = (props: SidebarProps): props is _SelfManaged => {
  return 'customButtons' in props;
};

export const isSidebarTitleButton = (b: SidebarTitleButton | JSX.Element): b is SidebarTitleButton => {
  return 'faIcon' in b;
};

const Sidebar: React.FC<SidebarProps> = props => {
  const CSSBlock = 'sidebar';

  return (
    <div className={`${CSSBlock}`}>
      <SidebarDesktop {...props} />
      <SidebarMobile {...props} />
    </div>
  );
};

export default Sidebar;
