import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectCustomer } from '@core/store/userSlice/userSlice';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {}

const Home: React.FC<Props> = ({}) => {
  const CSSBlock = 'home';
  const customer = useAppSelector(selectCustomer);

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__title`}>Hey {customer?.nickname}!</div>
      <div className={`${CSSBlock}__description`}>Welcome to the Demmi Customer Portal!</div>
      <div className={`${CSSBlock}__description`}>
        We're thrilled to have you here! <FontAwesomeIcon icon={icon({ style: 'thin', name: 'face-smile-halo' })} />
      </div>
      <div className={`${CSSBlock}__description`}>
        Just a heads up, while you're navigating around, you might notice some areas tagged as 'Coming Soon.' Don't
        worry, though! Our team is busy sprinkling magic behind the scenes to bring you more exciting features. Stay
        tuned for updates, and thank you for joining us on this journey!
      </div>
      <div className={`${CSSBlock}__description`}>
        In the meantime, you can make sure we have the correct details for your account, and invite up to 3 people to
        join the early access to Demmi!
      </div>
    </div>
  );
};

export default Home;
