import React from 'react';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  text: string;
  icon?: IconDefinition;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  styles?: React.CSSProperties;
}

const MenuTriggerButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ text, icon: faIcon, disabled, onClick, styles }, ref) => {
    const CSSBlock = 'demmi-ui-menu-trigger-button';

    return (
      <button
        className={`demmi-ui-button ${CSSBlock}`}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        style={styles}>
        <div className={` ${CSSBlock}__content`}>
          {faIcon && (
            <span className={`${CSSBlock}__icon`}>
              <FontAwesomeIcon icon={faIcon} />
              {/* <i
                className={
                  faStyles.find(item => faIcon.includes(item))
                    ? faIcon
                    : `fa-solid ${faIcon}`
                }></i> */}
            </span>
          )}
          <span className={`${CSSBlock}__text`}>{text}</span>
        </div>
        <span className={`${CSSBlock}__dropdown-icon`}>
          <FontAwesomeIcon
            icon={icon({ style: 'solid', name: 'caret-down' })}
          />
        </span>
      </button>
    );
  }
);

export default MenuTriggerButton;
