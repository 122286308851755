import React from 'react';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen05: React.FC<Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <ScreenWrapper
      cardIndex={5}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={'Give us a sec...'}>
      <div className={`${CSSBlock}__description`}>
        We're just setting everything up
      </div>
      <div className={`${CSSBlock}__description`}>
        This should only take a few seconds.
      </div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </ScreenWrapper>
  );
};

export default Screen05;
