import { DocumentData, Timestamp } from 'firebase/firestore';

export const CLOUD_FUNCTION_BASE_URL = 'https://us-central1-demmi-dev.cloudfunctions.net';
export enum CLOUD_FUNCTIONS {
  ON_SETUP_COMPLETE = 'http-users-setupComplete',
}
export const getCloudFunctionURL = (func: CLOUD_FUNCTIONS) => `${CLOUD_FUNCTION_BASE_URL}/${func}`;

export enum FSCollectionNames {
  PRODUCTS = 'products',
  FARMS = 'farms',
  FARMERS = 'farmers',
  USERS = 'users',
  ORDERS = 'orders',
  COUPONS = 'coupons',
  DEMMI = 'demmi',
  CATEGORIES = 'categories',
  REFUND_REQUEST = 'refund_requests',
  DEMMI_SUPPORT = 'demmi_support',
  NOTIFICATIONS = 'notifications_farmer',
  INVITE_CODES = 'invite_codes',
}

export enum FSSubCollectionNames {
  VARIANTS = 'variants',
  REVIEWS = 'reviews',
  DEMMI_SUPPORT_MESSAGES = 'messages',
}

export const getDateFromFirestore = (jsonTimestamp?: DocumentData): Date | undefined => {
  if (!jsonTimestamp) return;
  const date = new Timestamp(jsonTimestamp.seconds, jsonTimestamp.nanoseconds);
  return date.toDate();
};
