import React from 'react';
import PhoneInput, { Country } from 'react-phone-number-input/input';

import DemmiInput from '@demmi-ui/Input';
import DemmiInputPhone from '@demmi-ui/InputPhone';
import { FBAuthProviderInfo, getAuthProviderName, isAuthAppleObscured } from '@helpers/authProvider.helper';
import { FSUserModel } from '@models/customer.model';

import ScreenWrapper from './ScreenWrapper';

interface Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  authProvider: FBAuthProviderInfo;
  customer: FSUserModel;
  onUpdateCustomer: (customer: FSUserModel) => void;
}
const Screen03: React.FC<Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
  authProvider,
  customer,
  onUpdateCustomer,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <ScreenWrapper
      cardIndex={3}
      isMounted={isMounted}
      mountedStyle={mountedStyle}
      unmountedStyle={unmountedStyle}
      title={'How should we reach you?'}>
      <div className={`${CSSBlock}__auth-email`}>
        {isAuthAppleObscured(authProvider) ? (
          <>
            <span>Your real Apple login email is obscured from us, but they've given the temporary email:</span>
            <span>
              <b>{Object.values(customer.fb_authProviders)[0].email}</b>
            </span>
            <span>If you'd like to be contacted directly, enter your correct email below.</span>
          </>
        ) : (
          `Your account will be linked to your ${getAuthProviderName(authProvider)} account: ${
            Object.values(customer.fb_authProviders)[0].email
          }`
        )}
      </div>
      <div className={`${CSSBlock}__inputs`}>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>Your contact email address</div>
          <DemmiInput
            onChangeValue={val => {
              onUpdateCustomer({ ...customer, email: val });
            }}
            type="text"
            placeholder="Email address"
            value={customer.email}
          />
        </div>
        <div className={`${CSSBlock}__input`}>
          <div className={`${CSSBlock}__input-title`}>Your phone number</div>
          <PhoneInput
            inputComponent={DemmiInputPhone}
            country={customer.address.countryCode as Country}
            value={customer.contactNumber}
            onChange={e =>
              onUpdateCustomer({
                ...customer,
                contactNumber: e?.toString() ?? '',
              })
            }
          />
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default Screen03;
